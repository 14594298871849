import * as React from "react";

function SvgComponent(props) {
  return (
<svg width="130" height="140" viewBox="0 0 130 140" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="66" cy="70" r="50" fill="#4DA0A1" fill-opacity="0.19"/>
<path d="M117 47L66 2L15 47" stroke="#4DA0A1" stroke-opacity="0.12" stroke-width="1.6"/>
<path d="M117 93L66 138L15 93" stroke="#4DA0A1" stroke-opacity="0.12" stroke-width="1.6"/>
<rect x="69.7" y="24.7" width="59.6" height="43.6" stroke="#4DA0A1" stroke-width="1.4"/>
<path d="M76.5 31.5H121.5M76.5 31.5V59.5M76.5 31.5L70 25.5V67L76.5 59.5M121.5 31.5V59.5M121.5 31.5L129 25.5V67L121.5 59.5M121.5 59.5H76.5" stroke="#4DA0A1" stroke-width="1.4"/>
<path d="M76.5 56L88.8494 43.6506C89.2171 43.2829 89.8054 43.2584 90.2024 43.5944L102.5 54M108.5 59.5L102.5 54M102.5 54L111.841 46.5269C112.222 46.2225 112.766 46.2367 113.13 46.5604L121.5 54" stroke="#4DA0A1" stroke-width="1.4"/>
<circle cx="104" cy="43" r="3.3" stroke="#4DA0A1" stroke-width="1.4"/>
<path d="M67.9992 111.959V102.959C67.9992 99.9586 64.9206 98.2771 62.5 98.2692M67.9992 111.959V114.959C68.1658 116.792 69.5992 120.459 73.9992 120.459C78.3852 120.459 97.7094 120.459 106.912 120.459C106.972 120.459 107.028 120.464 107.087 120.474C108.947 120.771 112.499 120.077 112.499 114.959C112.499 113.942 112.499 112.935 112.499 111.959M67.9992 111.959H112.499M112.499 111.959C112.499 107.937 112.499 104.434 112.499 102.959C112.499 100.517 114.885 98.5518 117.5 98.1124M62.5 98.2692C60.1627 98.2616 56.4993 99.9586 56.4992 102.959C56.4989 109.418 56.4991 124.037 56.4992 127.969C56.4992 128.521 56.9469 128.959 57.4992 128.959H122.999C123.551 128.959 123.999 128.515 123.999 127.963C123.999 123.659 123.999 106.714 123.999 103.043C123.999 102.981 123.993 102.927 123.981 102.866C123.187 98.8474 120.219 97.6555 117.5 98.1124M62.5 98.2692V85.9586C62.5 85.4063 62.9477 84.9586 63.5 84.9586H116.5C117.052 84.9586 117.5 85.4063 117.5 85.9586V98.1124" stroke="#4DA0A1" stroke-width="1.4"/>
<path d="M24.4992 54.9586H18.5453C18.205 54.9586 17.8873 55.1311 17.7071 55.4198C13.4579 62.2291 11.197 70.3469 16.7474 74.326C16.9078 74.4411 17.1043 74.5 17.3018 74.5H32.1124C32.3611 74.5 32.605 74.4068 32.7817 74.2317C37.7008 69.3545 34.179 61.3651 31.2748 55.5116C31.1064 55.1723 30.76 54.9586 30.3812 54.9586H24.4992ZM24.4992 54.9586V49.9586" stroke="#4DA0A1" stroke-width="1.4"/>
<rect x="24.7" y="74.6586" width="23.6" height="47.6" stroke="#4DA0A1" stroke-width="1.4"/>
<rect x="0.7" y="74.6586" width="22.6" height="47.6" stroke="#4DA0A1" stroke-width="1.4"/>
<path d="M0.7 122.659H7.86738L5.56738 127.259H0.7V122.659Z" stroke="#4DA0A1" stroke-width="1.4"/>
<path d="M42.5045 127.259L40.9712 122.659H48.3V127.259H42.5045Z" stroke="#4DA0A1" stroke-width="1.4"/>
<path d="M18.5 91.9586V104.459" stroke="#4DA0A1" stroke-width="2" stroke-linecap="round"/>
<path d="M30 91.9586V104.459" stroke="#4DA0A1" stroke-width="2" stroke-linecap="round"/>
<path d="M90 91.9586V104.459" stroke="#4DA0A1" stroke-width="2" stroke-linecap="round"/>
<path d="M30.008 44C30.008 46.9271 27.6351 49.3 24.708 49.3C21.7809 49.3 19.408 46.9271 19.408 44C19.408 41.0729 21.7809 38.7 24.708 38.7C27.6351 38.7 30.008 41.0729 30.008 44Z" stroke="#4DA0A1" stroke-width="1.4"/>
<circle cx="78.5" cy="92.4586" r="1.5" fill="#4DA0A1"/>
<circle cx="74.5" cy="96.4586" r="1.5" fill="#4DA0A1"/>
<circle cx="82.5" cy="96.4586" r="1.5" fill="#4DA0A1"/>
<circle cx="78.5" cy="100.459" r="1.5" fill="#4DA0A1"/>
<circle cx="102.5" cy="92.4586" r="1.5" fill="#4DA0A1"/>
<circle cx="106.5" cy="96.4586" r="1.5" fill="#4DA0A1"/>
<circle cx="98.5" cy="96.4586" r="1.5" fill="#4DA0A1"/>
<circle cx="102.5" cy="100.459" r="1.5" fill="#4DA0A1"/>
</svg>

  );
}

export default SvgComponent;
