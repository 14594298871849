import * as React from "react";

function SvgComponent(props) {
  return (

      <svg  viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg"  width={35.459} height={43.42}  {...props}>
<path d="M27.4483 3H11.8833C6.215 3 3 6.215 3 11.8833V27.45C3 33.1167 6.215 36.3333 11.8833 36.3333H27.4483C33.1167 36.3333 36.3333 33.1167 36.3333 27.45V11.8833C36.3167 6.215 33.1167 3 27.4483 3Z" fill="#456B6B"/>
<path d="M28.7998 28.1833C28.7148 28.9 28.1165 29.4333 27.4165 29.4333C26.6982 29.4333 26.0998 28.9 26.0332 28.1833V22.7167C25.9665 22.1817 26.2148 21.6833 26.6665 21.4C27.1332 21.1167 27.6998 21.1167 28.1482 21.4C28.5998 21.6833 28.8482 22.1817 28.7998 22.7167V28.1833Z" fill="#FFDC60"/>
<path d="M21.132 28.1834C21.0653 28.9001 20.467 29.4334 19.7487 29.4334C19.0487 29.4334 18.4503 28.9001 18.3653 28.1834V11.2001C18.317 10.6834 18.5653 10.1651 19.017 9.8834C19.4653 9.60007 20.032 9.60007 20.5003 9.8834C20.9503 10.1651 21.1987 10.6834 21.132 11.2001V28.1834Z" fill="#FFDC60"/>
<path d="M13.3651 28.1835C13.2984 28.9002 12.7001 29.4335 11.9818 29.4335C11.2651 29.4335 10.6651 28.9002 10.6001 28.1835V16.6669C10.5501 16.1485 10.7984 15.6335 11.2501 15.3502C11.6984 15.0669 12.2668 15.0669 12.7168 15.3502C13.1651 15.6335 13.4168 16.1485 13.3651 16.6669V28.1835Z" fill="#FFDC60"/></svg>

  );
}

export default SvgComponent;
