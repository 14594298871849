import * as React from "react";

function SvgComponent(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={39.581} height={39.58} viewBox="0 0 39.581 39.58" {...props}>
<path d="M21.6433 11.4134H7.25703C3.24934 11.4134 0 14.591 0 18.5103V26.9031C0 30.8224 3.24934 34.0001 7.25703 34.0001H21.6433C25.6509 34.0001 28.9003 30.8224 28.9003 26.9031V18.5103C28.9003 14.591 25.6509 11.4134 21.6433 11.4134Z" fill="#456B6B"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M20.7186 19.0723C21.2645 19.7173 21.184 20.6827 20.5389 21.2286L13.9089 26.8386C13.5825 27.1148 13.1554 27.2418 12.7311 27.1889C12.3068 27.1359 11.9241 26.9078 11.6756 26.5599L9.12553 22.9899C8.63438 22.3023 8.79364 21.3467 9.48125 20.8556C10.1689 20.3644 11.1244 20.5237 11.6156 21.2113L13.2002 23.4298L18.5623 18.8926C19.2074 18.3467 20.1728 18.4272 20.7186 19.0723Z" fill="#FFDC60"/>
<path d="M23.8386 9.17321V11.6735C23.234 11.5045 22.5948 11.4201 21.9384 11.4201H20.8156V9.17321C20.8156 5.74381 17.9653 2.95637 14.4585 2.95637C10.9518 2.95637 8.10152 5.72692 8.08424 9.13942V11.4201H6.97867C6.30497 11.4201 5.66581 11.5045 5.0612 11.6903V9.17321C5.07848 4.10514 9.27619 0 14.424 0C19.6409 0 23.8386 4.10514 23.8386 9.17321Z" fill="#FFDC60"/>
</svg>

  );
}

export default SvgComponent;
