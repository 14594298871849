import * as React from "react";
import styled from "styled-components";

function ImageComponent(props) {
  return (
    <Wrapper><img
    src="https://shopeg.in/i/eg.png" height={40} wviewBox="0 0 27 40"
    className="lg:h-[20px] xl:h-[23px] 2xl:h-[26px] 3xl:h-[31px] lg:w-[19px] xl:w-[22px] 2xl:w-[25px] 3xl:w-[30px]"
    /> </Wrapper>
  );
}

const Wrapper = styled.ul`
  display: flex;

  @media (max-width: 760px) {
    display: flex;
    width: 40px;
    height: 40px;
  }

  @media (max-width: 550px) {
    display: flex;
    width: 40px;
    height: 40px;
  }

  @media (max-width: 912px) {
    display: flex;
    width: 40px;
    height: 40px;
  }

  @media (max-height: 600px) {
    display: flex;
    width: 40px;
    height: 40px;
  }
`;

export default ImageComponent;
