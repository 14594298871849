import * as React from "react";

function SvgComponent(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={39.581} height={39.58} viewBox="0 0 39.581 39.58" {...props}>
  <path d="M23.9281 0H29.6841C32.068 0 33.9996 1.94791 33.9996 4.35185V10.1565C33.9996 12.5604 32.068 14.5083 29.6841 14.5083H23.9281C21.5443 14.5083 19.6127 12.5604 19.6127 10.1565V4.35185C19.6127 1.94791 21.5443 0 23.9281 0Z" fill="#FFDC60"/>
<path d="M4.31541 19.4912H10.0714C12.4552 19.4912 14.3868 21.4391 14.3868 23.843V29.6477C14.3868 32.05 12.4552 33.9995 10.0714 33.9995H4.31541C1.9316 33.9995 0 32.05 0 29.6477V23.843C0 21.4391 1.9316 19.4912 4.31541 19.4912Z" fill="#FFDC60"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M10.0714 0H4.31541C1.9316 0 0 1.94791 0 4.35185V10.1565C0 12.5604 1.9316 14.5083 4.31541 14.5083H10.0714C12.4552 14.5083 14.3868 12.5604 14.3868 10.1565V4.35185C14.3868 1.94791 12.4552 0 10.0714 0ZM29.6839 19.491H23.9279C21.5441 19.491 19.6125 21.4389 19.6125 23.8428V29.6475C19.6125 32.0498 21.5441 33.9993 23.9279 33.9993H29.6839C32.0677 33.9993 33.9993 32.0498 33.9993 29.6475V23.8428C33.9993 21.4389 32.0677 19.491 29.6839 19.491Z" fill="#456B6B"/>
</svg>

  );
}

export default SvgComponent;
