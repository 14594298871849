// import React from "react";
import "./styles/color.css";
import "./styles/font.css";
import "./styles/index.css";
import "./styles/tailwind.css";
import App from "App";
// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById("root")
// );

import React from 'react';
import ReactDOM from 'react-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "/Users/pandora/Code/Web/shopeg-d2ctrust-web/src/styles/flexboxgrid.min.css";
import "./styles/index.css";
import Landing from 'pages/Home2/landing';
import reportWebVitals from '/Users/pandora/Code/Web/shopeg-d2ctrust-web/src/reportWebVitals.js';


ReactDOM.render(
  <>
  <React.StrictMode>
    <Landing />
    </React.StrictMode>,
  </>,
  document.getElementById('root')
);

reportWebVitals();
