import * as React from "react";

function SvgComponent(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={43.336} height={32.531} viewBox="0 0 43.336 32.531" {...props}>
    <path d="M16.0743 7.27577C16.2143 7.70415 16.1323 8.17405 15.8556 8.52977L9.14143 17.1623C8.6947 17.7367 7.87436 17.8563 7.28226 17.4333L0.568043 12.6375C0.228733 12.3951 0.0197459 12.0102 0.00132669 11.5936C-0.0170934 11.177 0.157102 10.7751 0.473695 10.5038L7.18791 4.74871C7.53377 4.45225 8.00534 4.34919 8.44335 4.47434L15.1576 6.39268C15.5909 6.51649 15.9344 6.8474 16.0743 7.27577Z" fill="#FFDC60"/>
<path d="M26.7158 17.9175C26.2875 17.7776 25.8176 17.8595 25.4619 18.1362L16.8293 24.8504C16.2549 25.2971 16.1353 26.1175 16.5583 26.7096L21.3541 33.4238C21.5965 33.7631 21.9815 33.9721 22.398 33.9905C22.8146 34.0089 23.2165 33.8347 23.4879 33.5181L29.2429 26.8039C29.5394 26.4581 29.6424 25.9865 29.5173 25.5485L27.5989 18.8343C27.4751 18.401 27.1442 18.0574 26.7158 17.9175Z" fill="#FFDC60"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M33.3599 1.59558C33.2353 1.12006 32.8631 0.749209 32.3872 0.626232C29.1102 -0.220482 25.7625 -0.213157 22.6891 0.676097C19.6128 1.56618 16.9326 3.30559 14.9373 5.72425L14.9344 5.72783L12.4795 8.72441C12.4601 8.74815 12.4414 8.77255 12.4237 8.79755C12.4207 8.80168 12.4177 8.80525 12.4147 8.80841C12.391 8.83322 12.3682 8.85892 12.3465 8.88546L9.47395 12.388L9.47133 12.3912C7.15708 15.2307 5.75288 18.7376 5.38328 22.534C5.34397 22.9378 5.48745 23.338 5.77437 23.6248L10.5996 28.4483C10.872 28.7206 11.2475 28.8643 11.6322 28.8434C15.455 28.6355 18.9992 27.2775 21.7973 24.912C21.8093 24.9019 21.8211 24.8915 21.8328 24.881L28.2659 19.0473C30.685 17.0668 32.4272 14.3993 33.3202 11.3333C34.2187 8.24844 34.2223 4.88475 33.3599 1.59558Z" fill="#456B6B"/>
<path d="M22.4979 11.414C23.5574 12.4735 25.2751 12.4735 26.3346 11.414C27.3941 10.3545 27.3941 8.63679 26.3346 7.57732C25.2751 6.51784 23.5574 6.51784 22.4979 7.57732C21.4384 8.63679 21.4384 10.3545 22.4979 11.414Z" fill="#FFDC60"/>
</svg>
  );
}

export default SvgComponent;
