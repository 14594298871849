import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
import { Text } from "./Text/index.js";
// Assets
import HomeDecor from "../assets/svg/Categories.jsx/HomeDecor.jsx";
import Accessories from "../assets/svg/Categories.jsx/Accessories.jsx";
import BeautyPersonalCare from "../assets/svg/Categories.jsx/BeautyPersonalCare.jsx";
import ConsumerElectronics from "../assets/svg/Categories.jsx/ConsumerElectronics.jsx";
import EdTech from "../assets/svg/Categories.jsx/EdTech.jsx";
import FashionApparels from "../assets/svg/Categories.jsx/FashionApparels.jsx";
import FinTech from "../assets/svg/Categories.jsx/FinTech.jsx";
import FoodBeverages from "../assets/svg/Categories.jsx/FoodBeverages.jsx";
import HealthWellness from "../assets/svg/Categories.jsx/HealthWellness.jsx";
import Insurance from "../assets/svg/Categories.jsx/Insurance.jsx";


export default function CSlider() {
  const settings = {
    infinite: true,
    slidesToShow: 3.5,
    slidesToScroll: 1,
    autoplay: true,
    speed: 400,
    autoplaySpeed: 2500,
    cssEase: "ease-out",
    dragable: true,
    swipeToSlide: true,
    arrows:false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
          infinite: true,
          autoplay: true,
          speed: 400,
          autoplaySpeed: 4500,
          cssEase: "ease-out",
          draggable: true,
          swipeToSlide: true,
          arrows:false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          autoplay: true,
          speed: 4500,
          autoplaySpeed: 4500,
          cssEase: "linear",
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          autoplay: true,
          autoplaySpeed: 5500,
          cssEase: "linear",
          rtl: false
        },
      },
    ],
  };
  return (
    <div>
      <Slider {...settings}>
        <LogoWrapper className="flexCenter">
         <HomeDecor/>
         <TextStyle><Text>Home Decor</Text></TextStyle>
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
         <Accessories/>
         <TextStyle><Text>Accessories</Text></TextStyle>
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <BeautyPersonalCare/>
          <TextStyle><Text>Beauty & Personal Care</Text></TextStyle>
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <ConsumerElectronics/>
          <TextStyle ><Text>Consumer Electronics</Text></TextStyle>
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <EdTech/>
          <TextStyle><Text>EdTech</Text></TextStyle>
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <FashionApparels/>
          <TextStyle><Text>Fashion & Apparels</Text></TextStyle>
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <FinTech/>
          <TextStyle><Text>FinTech</Text></TextStyle>
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <FoodBeverages />
          <TextStyle><Text>Food & Beverages</Text></TextStyle>
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <HealthWellness/>
          <TextStyle><Text>Health & Wellness</Text></TextStyle>
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <Insurance/>
          <TextStyle><Text>Insurance</Text></TextStyle>
        </LogoWrapper>
      </Slider>
    </div>
  );
}



const LogoWrapper = styled.div`
  height: 200px;
  cursor: pointer;
  flexDirection: 'column';
  padding: 20px;
  :focus-visible {
    outline: none;
    border: 0px;
  }
`;
const TextStyle = styled.text`
  color: black;
`;