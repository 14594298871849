import React from "react";
import styled from "styled-components";
import {
  Column,
  Row,
  Img,
  Text,
  Button,
  Input,
  Stack,
  Line,
  List,
} from "components";

import Product from "/Users/pandora/Code/Web/shopeg-d2ctrust-web/src/assets/svg/Product.jsx"
import Engine from "/Users/pandora/Code/Web/shopeg-d2ctrust-web/src/assets/svg/Engine.jsx"
import Customer from "/Users/pandora/Code/Web/shopeg-d2ctrust-web/src/assets/svg/Customer.jsx"

export default function BlogBox({ tag, title, text, author, icon }) {

  let getIcon;

  switch (icon) {
    case "product":
      getIcon = <Product />;
      break;
    case "engine":
      getIcon = <Engine />;
      break;
    case "customer":
      getIcon = <Customer />;
      break;
    default:
      getIcon = <Product />;
      break;
  }


  return (
    <WrapperBtn>
    <Wrapper className="whiteBg radius8 ">
     <Wrapper>
     <IconStyle>{getIcon}</IconStyle>
     </Wrapper>
      <h3 className="font20 extraBold">{title}</h3>
      <p className="font13" style={{ padding: "30px 0" }}>
          {text}
        </p>
      
      </Wrapper>
      </WrapperBtn>
    // <WrapperBtn className="animate pointer" onClick={action ? () => action() : null}>
    //   <Wrapper className="whiteBg radius8 shadow">
    //     <h3 className="font20 extraBold">{title}</h3>
    //     <p className="font13" style={{ padding: "30px 0" }}>
    //       {text}
    //     </p>
    //     <p className="font13 extraBold">{author}</p>
    //     <div className="flex">
    //       <p className="tag coralBg radius6 font13 extraBold">{tag}</p>
    //     </div>
    //   </Wrapper>
    // </WrapperBtn>
  );
}

const Wrapper = styled.div`
  width: 100%;
  text-align: left;
  padding: 20px 30px 10px 10px;
  margin-top: 30px;

`;
const WrapperBtn = styled.button`
  border: 0px;
  outline: none;
  background-color: transparent;
  // :hover {
  //   opacity: 0.5;
  // }
`;

const IconStyle = styled.div`
  @media (max-width: 860px) {
    margin: 0 auto;
  }
`;
