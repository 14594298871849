import React from "react";
import { Helmet } from "react-helmet";

// Sections
import TopNavbar from "components/Nav/TopNavbar";
import Header from "components/sections/Header";
import Services from "components/sections/Services";
import Blog from "components/sections/Blog";
// import Projects from "../components/Sections/Projects";
// import Pricing from "../components/Sections/Pricing";
// import Contact from "../components/Sections/Contact";
import Footer from "components/sections/Footer";
import CSlider from "components/Slider";



export default function Landing() {
  return (
    <>
    <TopNavbar />
    <Header />
    <div className="lightGreyBg" style={{  padding: "20px 10px" }}>
        <div className="container">
        <CSlider />
        </div>
      </div>
    <Blog />
    <Services />
    <Footer /> 
    </>
  );
}
