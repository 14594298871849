import React from "react";
import styled from "styled-components";
// Components
import { Text } from "components/Text";
// Assets
import Dots from "../../assets/svg/Dots";
import { Button } from "components/Button";

export default function Header() {

  const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <Wrapper id="home" className="container flexCenter">

    <LeftSide className="flexCenter ">
      <div>
      <Text className="extraBold font60 ">
                Take your D2C brand  <span style={{fontStyle: "italic"}}>offline</span>
              </Text>
        <HeaderP className="font28 ">
        Leverage our network of offline trusted retailers to increase your online sales via referrals
        </HeaderP>
        <BtnWrapper>
          <Button title="Get Started" onClick={()=>openInNewTab('https://forms.gle/cX5TH1xp498ia6gx7')}>  Scale now</Button>
        </BtnWrapper>
      </div>
    </LeftSide>
    
    <RightSide>
      
      <ImageWrapper>
        
      
        <Img className="radius8" src="images/img_shop1.jpg" alt="office" style={{zIndex: 9}} />
        <QuotesWrapper className="darkBg radius8" style={{marginTop:"10px", marginBottom:"20px", marginLeft:"120px" ,padding:"4px", zIndex: 9}}>
            <h1 className="darkBg radius8 font15 whiteColor" style={{padding:"8px"}}>Fintech Referral Campaign
              </h1>
              {/* <QuotesIcon style={{"padding-bottom":"18px"}}/> */}
            </QuotesWrapper>
        
        <QuoteWrapper className="flexCenter radius8">
          
        <Img className="darkBg radius8" src="images/img_shop4.jpg" alt="office" style={{zIndex: 9,padding:"16px"}} />
        
   
          <QuotesWrapper className="darkBg radius8">
            
          
          <h1 className="darkBg radius8 font15 whiteColor" style={{padding:"18px"}}>Healthcare referral
            </h1>
            {/* <QuotesIcon style={{"padding-bottom":"18px"}}/> */}
          </QuotesWrapper>
          
          {/* <div>
            <p className="font15 whiteColor">
              <em>Friends, such as we desire, are dreams and fables. Friendship demands the ability to do without it.</em>
            </p>
            <p className="font13 orangeColor textRight" style={{marginTop: '10px'}}>Ralph Waldo Emerson</p>
          </div> */}
        </QuoteWrapper>
        
        <DotsWrapper>
        <QuotesWrapper className="darkBg radius8" style={{marginBottom:"20px", marginLeft:"120px" , zIndex: 9}}>
            <h1 className="darkBg radius8 font22 whiteColor" style={{padding:"8px"}}>₹70 CAC
              </h1>
              {/* <QuotesIcon style={{"padding-bottom":"18px"}}/> */}
            </QuotesWrapper>
        
          
          <Dots />
        </DotsWrapper>
      </ImageWrapper>
      <GreyDiv className="lightBg"></GreyDiv>
      
    </RightSide>
  </Wrapper>
  );
}


const Wrapper = styled.section`
  padding-top: 80px;
  // width: 100%;
  min-height: 840px;
  position: relative;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;
const LeftSide = styled.div`
  width: 50%;
  height: 100%;

  @media (max-width: 960px) {
    width: 100%;
    order: 2;
    margin: 50px 0;
    text-align: center;
  }
  @media (max-width: 560px) {
    margin: 80px 0 50px 0;
    padding-left: 0px;
  }
  @media (max-width: 960px) {
    margin: 80px 0 50px 0;
    padding-left: 0px;
  }
`;
const RightSide = styled.div`
  width: 50%;
  height: 80%;
  display: flex;
  @media (max-width: 960px) {
    width: 100%;
    order: 1;
    margin-top: 30px;
    
  }
  @media (max-width: 560px) {
    margin: 80px 0 50px 0;
  }
`;
const HeaderP = styled.div`
  max-width: 470px;
  position: relative;
  padding: 15px 0 50px 0;
  line-height: 1.5rem;
  @media (max-width: 960px) {
    padding: 15px 0 50px 0;
    text-align: center;
    max-width: 100%;
  }
`;
const BtnWrapper = styled.div`
  max-width: 190px;
  @media (max-width: 960px) {
    margin: 0 auto;
  }
`;
const GreyDiv = styled.div`
  width: 30%;
  height: 700px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
  @media (max-width: 960px) {
    display: none;
  }
`;
const ImageWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 9;
  @media (max-width: 960px) {
    width: 100%;
    justify-content: center;
  }
`;
const Img = styled.img`
height: 80%;
  @media (max-width: 560px) {
    width: 80%;
    height: 80%;
  }
`;
const QuoteWrapper = styled.div`
  position: absolute;
  left: 0;
  bottom: 50px;
  max-width: 330px;
  padding: 30px;
  z-index: 99;
  @media (max-width: 960px) {
    left: 20px;
  }
  @media (max-width: 560px) {
    bottom: -50px;
  }
`;
const QuotesWrapper = styled.div`
  position: absolute;
  left: -20px;
  top: -10px;
`;
const DotsWrapper = styled.div`
  position: absolute;
  right: -100px;
  bottom: 100px;
  z-index: 2;
  @media (max-width: 960px) {
    right: 100px;
  }
  @media (max-width: 560px) {
    display: none;
  }
`;


