import * as React from "react";

function SvgComponent(props) {
  return (
<svg width="250" height="250" viewBox="0 0 500 500" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1_5)">
<g filter="url(#filter0_dd_1_5)">
<rect x="15" y="140" width="400" height="250" rx="10" fill="white"/>
</g>
<g filter="url(#filter1_dd_1_5)">
<rect x="236" y="172" width="203" height="75" rx="10" fill="white"/>
</g>
<g filter="url(#filter2_dd_1_5)">
<rect x="236" y="82" width="203" height="75" rx="10" fill="#4DA0A1"/>
</g>
<g filter="url(#filter3_dd_1_5)">
<rect x="66" y="285" width="203" height="59" rx="10" fill="white"/>
</g>
<g filter="url(#filter4_dd_1_5)">
<rect x="66" y="179" width="74" height="74" rx="6" fill="#FADB73"/>
</g>
<g filter="url(#filter5_dd_1_5)">
<rect x="382" y="314" width="103" height="103" rx="6" fill="#4DA0A1"/>
</g>
<path fill-rule="evenodd" clip-rule="evenodd" d="M427.758 348.757C426 350.515 426 353.343 426 359V365.794C425.522 365.87 425.157 366.284 425.158 366.783L425.158 367.258C425.159 367.756 425.523 368.169 426 368.244V372C426 377.657 426 380.485 427.758 382.243C429.515 384 432.343 384 438 384C443.657 384 446.485 384 448.243 382.243C450 380.485 450 377.657 450 372V359C450 353.343 450 350.515 448.243 348.757C446.485 347 443.657 347 438 347C432.343 347 429.515 347 427.758 348.757ZM426 368.244C426.052 368.253 426.105 368.257 426.158 368.257L432.142 368.257L428.808 371.538C428.408 371.931 428.41 372.576 428.811 372.967L429.169 373.316C429.559 373.696 430.181 373.695 430.569 373.313L436.272 367.7C436.672 367.307 436.67 366.662 436.269 366.271L430.539 360.684C430.149 360.304 429.527 360.305 429.139 360.687L428.783 361.038C428.383 361.431 428.385 362.076 428.786 362.467L432.186 365.782H426.158C426.104 365.782 426.052 365.786 426 365.794V368.244Z" fill="white"/>
<path d="M102.521 216.099C106.703 216.099 110.097 212.717 110.097 208.55C110.097 204.382 106.703 201 102.521 201C98.3378 201 94.9436 204.382 94.9436 208.55C94.9436 212.717 98.3378 216.099 102.521 216.099Z" fill="white"/>
<path d="M115.514 222.112C115.309 221.634 115.034 221.155 114.76 220.711C113.252 218.491 110.886 216.988 108.246 216.612C107.903 216.578 107.56 216.646 107.286 216.851C105.915 217.842 104.269 218.388 102.555 218.388C100.841 218.388 99.1949 217.842 97.8234 216.851C97.5491 216.646 97.2064 216.578 96.8634 216.612C94.2235 216.988 91.8578 218.456 90.3494 220.711C90.0409 221.155 89.8008 221.633 89.5951 222.112C89.4923 222.317 88.9093 223.339 89.0121 223.51C89.2864 223.954 89.9612 224.638 89.9612 224.638C89.9612 224.638 91.0422 225.799 91.8283 226.438C92.5606 227.033 93.0046 227.331 93.818 227.811C96.8757 229.612 99.0514 230.19 102.603 230.159C106.434 230.127 108.78 229.373 111.971 227.262C112.54 226.885 113.379 226.225 113.379 226.225L115.001 224.761C115.001 224.761 115.859 224.181 115.981 223.601C116.106 223.005 115.583 222.317 115.514 222.112H115.514Z" fill="white"/>
<rect x="258" y="202" width="160" height="10" rx="5" fill="#B4B6C3"/>
<rect x="258" y="112" width="160" height="10" rx="5" fill="white"/>
<rect x="88" y="309" width="160" height="10" rx="5" fill="#B4B6C3"/>
<path d="M418 366.792C418 366.24 418.448 365.792 419 365.792H426V368.251H419C418.448 368.251 418 367.803 418 367.251V366.792Z" fill="white"/>
</g>
<defs>
<filter id="filter0_dd_1_5" x="-17" y="132" width="464" height="314" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="24"/>
<feGaussianBlur stdDeviation="16"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.196487 0 0 0 0 0.196487 0 0 0 0 0.279476 0 0 0 0.08 0"/>
<feBlend mode="multiply" in2="BackgroundImageFix" result="effect1_dropShadow_1_5"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="16"/>
<feGaussianBlur stdDeviation="8"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.196487 0 0 0 0 0.196487 0 0 0 0 0.279476 0 0 0 0.08 0"/>
<feBlend mode="multiply" in2="effect1_dropShadow_1_5" result="effect2_dropShadow_1_5"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_1_5" result="shape"/>
</filter>
<filter id="filter1_dd_1_5" x="204" y="164" width="267" height="139" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="24"/>
<feGaussianBlur stdDeviation="16"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.196487 0 0 0 0 0.196487 0 0 0 0 0.279476 0 0 0 0.08 0"/>
<feBlend mode="multiply" in2="BackgroundImageFix" result="effect1_dropShadow_1_5"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="16"/>
<feGaussianBlur stdDeviation="8"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.196487 0 0 0 0 0.196487 0 0 0 0 0.279476 0 0 0 0.08 0"/>
<feBlend mode="multiply" in2="effect1_dropShadow_1_5" result="effect2_dropShadow_1_5"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_1_5" result="shape"/>
</filter>
<filter id="filter2_dd_1_5" x="204" y="74" width="267" height="139" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="24"/>
<feGaussianBlur stdDeviation="16"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.196487 0 0 0 0 0.196487 0 0 0 0 0.279476 0 0 0 0.08 0"/>
<feBlend mode="multiply" in2="BackgroundImageFix" result="effect1_dropShadow_1_5"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="16"/>
<feGaussianBlur stdDeviation="8"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.196487 0 0 0 0 0.196487 0 0 0 0 0.279476 0 0 0 0.08 0"/>
<feBlend mode="multiply" in2="effect1_dropShadow_1_5" result="effect2_dropShadow_1_5"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_1_5" result="shape"/>
</filter>
<filter id="filter3_dd_1_5" x="34" y="277" width="267" height="123" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="24"/>
<feGaussianBlur stdDeviation="16"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.196487 0 0 0 0 0.196487 0 0 0 0 0.279476 0 0 0 0.08 0"/>
<feBlend mode="multiply" in2="BackgroundImageFix" result="effect1_dropShadow_1_5"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="16"/>
<feGaussianBlur stdDeviation="8"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.196487 0 0 0 0 0.196487 0 0 0 0 0.279476 0 0 0 0.08 0"/>
<feBlend mode="multiply" in2="effect1_dropShadow_1_5" result="effect2_dropShadow_1_5"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_1_5" result="shape"/>
</filter>
<filter id="filter4_dd_1_5" x="34" y="171" width="138" height="138" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="24"/>
<feGaussianBlur stdDeviation="16"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.196487 0 0 0 0 0.196487 0 0 0 0 0.279476 0 0 0 0.08 0"/>
<feBlend mode="multiply" in2="BackgroundImageFix" result="effect1_dropShadow_1_5"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="16"/>
<feGaussianBlur stdDeviation="8"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.196487 0 0 0 0 0.196487 0 0 0 0 0.279476 0 0 0 0.08 0"/>
<feBlend mode="multiply" in2="effect1_dropShadow_1_5" result="effect2_dropShadow_1_5"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_1_5" result="shape"/>
</filter>
<filter id="filter5_dd_1_5" x="350" y="306" width="167" height="167" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="24"/>
<feGaussianBlur stdDeviation="16"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.196487 0 0 0 0 0.196487 0 0 0 0 0.279476 0 0 0 0.08 0"/>
<feBlend mode="multiply" in2="BackgroundImageFix" result="effect1_dropShadow_1_5"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="16"/>
<feGaussianBlur stdDeviation="8"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.196487 0 0 0 0 0.196487 0 0 0 0 0.279476 0 0 0 0.08 0"/>
<feBlend mode="multiply" in2="effect1_dropShadow_1_5" result="effect2_dropShadow_1_5"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_1_5" result="shape"/>
</filter>
<clipPath id="clip0_1_5">
<rect width="500" height="500" fill="white"/>
</clipPath>
</defs>
</svg>

  );
}

export default SvgComponent;
